import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CleanScreen from './CleanScreen';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Navbar /> */}
        <Routes>
          <Route exact path = "/" element = {<CleanScreen />} />
          {/* <Route exact path = "/" element = {<HomeScreen />} />
          <Route exact path = "/team" element = {<TheTeam />} />
          <Route exact path = "/speakers" element = {<Speakers />} />
          <Route exact path = "/sponsors" element = {<Sponsors />} />
          <Route exact path = "/agenda" element = {<Agenda />} />
          <Route exact path = "/about" element = {<About />} /> */}
        </Routes>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
