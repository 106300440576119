import HeroLogo from './assets/Logo-Center.svg';

const CleanScreen = () => {
    return (
        <div className="min-h-screen bg-[#FFFFF] text-white flex flex-col items-center justify-center p-8">
          <div className="w-full max-w-4xl space-y-12 text-center">
            {/* <div className="space-y-4">
              <h1 className="text-7xl font-bold leading-tight">
                Google Cloud Community Day Kochi 2024
                <span className="block text-blue-400">Google Cloud</span>
                <span className="block text-red-400">Community Day</span>
                <span className="block text-yellow-400">Kochi</span>
                <span className="block text-green-400">2024</span>
              </h1>
            </div> */}
            <div className="w-full max-w-2xl mx-auto">
                <img src={HeroLogo} alt="" className="website-banner-image" />
            </div>
            <p className="text-xl font-bold max-w-2xl mx-auto">
              Redefining what's possible ✨
            </p>
            
            <div className="flex justify-center space-x-4">
              <button 
                onClick={() => window.open('https://konfhub.com/google-cloud-community-day-kochi-2024', '_blank')}
                className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors flex items-center"
              >
                Register Now
              </button>
            </div>
            
            <div className="text-sm text-gray-400">
              #GoogleCloudCommunityDay #GCCD2024 #GoogleCloudCommunityDayKochi #CloudTechnology #MakeADifference
              #GDGCochin #GDGCloudKochi
            </div>

            <div className="text-sm text-gray-400">
              Stay tuned for a revolutionary experience!
            </div>
          </div>
        </div>
      );
}
 
export default CleanScreen;